.gallery {
	background-color: $brandLightGrey;
	display: grid;
	grid-template-rows: repeat(3, minmax(min-content, max-content));

	@include breakpoint(1200) {
		grid-template-rows: repeat(2, minmax(min-content, max-content));
		grid-template-columns: 1fr 30%;
	}

	&--home,
	&--child {
		@include breakpoint(1200) {
			grid-column: 1 / 3;
			grid-row: 1 / 3;
		}
	}

	&--parent {
		@include breakpoint(1200) {
			grid-template-rows: 1fr;
			grid-template-columns: 1fr 30%;
		}
	}
	
	.titlebox {
		grid-row: 1 / 2;
		z-index: 4;

		&:before {
			position: absolute;
			bottom: -35px;
			left: 50%;
			transform: translateX(-50%) rotate(180deg);
		}

		@include breakpoint(1200) {
			grid-column: 2 / 3;
		}
	}
	
	&__text {
		text-align: center;
		margin: 0;
		font-weight: 600;
		font-size: 1.8rem;

		&:first-of-type {
			font-size: 1.6rem;
		}

		&--white {
			color: $blanc;
		}

		&--green {
			color: $brandDarkGreen;
		}
	}

	&__grid {
		align-self: center;
		justify-self: center;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		@include breakpoint(1200) {
			grid-column: 2 / 3;
			grid-row: 2 / 3;
		}
	}

	&__thumbs {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 50px 0 0;

		@supports(display: grid) {
			display: grid;
			grid-template-rows: repeat(3, 125px);
			grid-template-columns: repeat(2, 125px);
			grid-gap: 15px;
			align-self: center;
			justify-self: center;

			@include breakpoint(480) {
				grid-template-rows: 1fr;
				grid-template-columns: repeat(6, minmax(min-content, 125px));
			}

			@include breakpoint(1200) {
				grid-column: 1 / 2;
				grid-template-rows: repeat(3, 150px);
				grid-template-columns: repeat(2, 150px);
			}
		}
	}

	&__link {
		width: 150px;
		height: 150px;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: opacity .3s ease-in-out;
			background-color: $brandDarkGreen;
			opacity: 0;
		}

		@supports(display: grid) {
			width: 100%;
			height: 100%;
		}

		&--active:before {
			opacity: .5;
		}
	}

	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		display: block;
	}

	.btn {
		align-self: center;
		justify-self: center;
		margin-top: 15px;
		margin-bottom: 50px;
	}

	&__full {
		display: none;

		@include breakpoint(1200) {
			display: block;
			grid-row: 2 / 3;
			grid-column: 1 / 2;
			width: 100%;
			height: 100%;
			object-fit: cover;
			background-color: $brandLightGrey;
			transition: all .6s ease-in-out;
			opacity: 1;
			max-height: 650px;

			&:hover {
				cursor: pointer;
			}

			&--hidden {
				opacity: 0;
			}

			body.internal & {
				grid-row: 1 / 3;
				grid-column: 1 / 2;
			}
		}
	}
}

#baguetteBox-overlay {
	background-color: rgba(#000, .9) !important;

	#close-button,
	#next-button,
	#previous-button {
		background-color: $brandDarkGreen;
		border-radius: 0;
		height: 50px;
		width: 50px;

		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			g,
			polyline {
				stroke: white;
			}
		}
	}

	.baguetteBox-counter {
		background-color: $brandDarkGreen;
		color: $blanc;
		height: 50px;
		position: absolute;
		bottom: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 15px;
	}

	#close-button {
		right: 0;
		top: 0;
	}

	#previous-button,
	#next-button {
		svg{ 
			left: 200%;
		}

		&:before {
			font-family: 'solo-blinds';
			color: $blanc;
			position: absolute;
			left: 50%;
			top: 50%;
		}
	}

	#previous-button {
		top: auto;
		left: auto;
		bottom: 0;
		right: 50px;

		&:before {
			content: '\68';
			transform: translate(-50%, -50%);
		}
	}

	#next-button {
		top: auto;
		bottom: 0;
		right: 0;

		&:before {
			content: '\67';
			transform: translate(-50%, -50%);
		}
	}
}

.gallery-brands {
	@include breakpoint(1200) {
		display: grid;
		grid-template-rows: 190px 1fr;
		grid-template-columns: 1fr 30%;
	}
}