.footer,
.mobile-navigation {
	background-color: $brandLightGreen;

	&:not(.footer--light) .container {
		padding: 25px 5px;

		@include breakpoint(600) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 15px;
			grid-template-rows: repeat(3, minmax(min-content, max-content));
		}

		@include breakpoint(1200) {
			padding: 50px;
		}
	}

	&__title {
		display: block;
		text-align: center;
		color: $brandDarkGreen;
		margin-bottom: 10px;

		@include breakpoint(1200) {
			text-align: left;
		}
	}

	&__text {
		text-align: center;
		color: $blanc;
		margin-bottom: 10px;

		@include breakpoint(1200) {
			text-align: left;
		}

		a {
			color: $blanc;
			display: block;
			font-weight: 900;
			text-decoration: none;

			@include breakpoint(1200) {
				display: inline-block;
			}

			&:hover {
				color: $brandDarkGreen;
			}
		}
	}

	&__col {
		margin-bottom: 10px;
		width: 100%;

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			list-style: none;

			@include breakpoint(1200) {
				justify-content: flex-start;
			}

			li.current-menu-item > a {
				font-weight: 900;
				background-color: $brandDarkGreen;
				padding: 5px;
			}
	
			a {
				color: $blanc;
				text-decoration: none;
				padding: 5px 10px;
				display: block;

				@include breakpoint(1200) {
					padding: 5px 0;
					margin-right: 15px;
				}

				&:hover {
					background-color: $brandDarkGreen;
					padding: 5px;
				}
			}
		}
		@include breakpoint(1200) {
			&:nth-of-type(1) {
				grid-row: 1 / 2;
				grid-column: 1 / 2;
			}
			&:nth-of-type(2) {
				grid-row: 2 / 3;
				grid-column: 1 / 2;
			}
			&:nth-of-type(3) {
				grid-row: 2 / 3;
				grid-column: 2 / 3;
			}
			&:nth-of-type(4) {
				grid-row: 1 / 2;
				grid-column: 2 / 3;
			}
			&:nth-of-type(5) {
				grid-row: 3 / 4;
				grid-column: 1 / 3;
			}
		}
	}

	&--light {
		background-color: $blanc;
		
		.container {
			padding: 25px 5px;

			@include breakpoint(1200) {
				display: flex;
				align-items: center;
				padding: 10px;
			}
		}

		.footer__text {
			color: $noir;
			font-size: 1.4rem;

			@include breakpoint(1200) {
				margin: 0;
			}
		}

		img {
			display: block;
			width: 65px;
			height: 65px;
			margin: 0 auto;

			@include breakpoint(1200) {
				width: 40px;
				height: 40px;
				margin: 0 0 0 auto;
			}
		}
	}

	.footer__accreds {
		margin-top: 40px;
		background: #fff;
		padding: 25px;
		display: inline-flex;

		img {
			max-width: 120px;
			margin-right: 25px;
		}

		li:last-child img {
			margin-right: 0;
		}
	}


	&-maps {
		background-color: $brandLightGrey;

		.container {
			display: grid;
			grid-template-rows: repeat(4, minmax(min-content, max-content));

			@include breakpoint(420) {
				grid-template-rows: repeat(2, minmax(min-content, max-content));
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 15px;
			}

			@include breakpoint(1200) {
				grid-template-rows: 1fr;
				grid-template-columns: repeat(4, 1fr);
				padding: 0;
			}
		}

		form {
			padding: 10px 0;

			input[type=text] {
				border: 1px solid $noir;
				padding: 8px;
				margin-right: 10px;
				border-radius: 5px;
			}

			@include breakpoint(1200) {
				padding: 0 50px 50px;
			}

			input[type=submit] {
				border: 0;
				margin: 10px 0;
			}
		}

		&__col {
			padding: 15px;

			@include breakpoint(1200) {
				padding: 0;
			}

			&.large {
				grid-column: span 2;
			}
		}

		&__text,
		&__title {
			margin-bottom: 5px;
			
			@include breakpoint(1200) {
				padding: 0 50px;
			}
		}

		&__title {
			@include breakpoint(1200) {
				padding-top: 50px;
			}
		}

		.map {
			height: 100% !important; 
		}


		span {
			display: inline-block;
		}

		a {
			display: block;
			color: $brandDarkGreen;
			text-decoration: none;
			font-weight: 900;

			&:hover {
				color: $brandLightGreen;
			}
		}
	}
}


/* Back to top
--------------------------------------------------------------- */

.back-top-wrap {
	position: fixed;
	cursor: pointer;
	right: 10px;
	bottom: 60px;
	z-index: 300;
	opacity: .4;
	text-align: center;
	background: $noir;
	padding: 6px 3px 3px;
	width: 40px;
	line-height: 1.2;

	@include breakpoint(800) {
			width: 50px;
	}

	&:hover {
			opacity: 1;
			transition: .3s;
		 // @include box-shadow(0 0 15px rgba($red,.5)); 
	}
	
	a {
		color: #fff;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 1.4rem;
		letter-spacing: 2px;
	}

	img {
		transform: rotate(180deg);
	}
}