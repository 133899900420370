.brands {
	display: grid;
	grid-template-rows: repeat(2, minmax(min-content, max-content));
	background-color: $blanc;
	z-index: 2;

	@include breakpoint(1200) {
		grid-template-rows: 1fr;
		grid-template-columns: 30% 1fr;
		grid-row: 1 / 2;
		grid-column: 1 / 2;
	}

	.titlebox {
		grid-row: 1 / 2;

		&:before {
			position: absolute;
			bottom: -35px;
			left: 50%;
			transform: translateX(-50%) rotate(180deg);
		}

		@include breakpoint(1200) {
			grid-column: 1 / 2;
			align-items: flex-start;
		}
	}

	&__text {
		text-align: center;
		margin: 0;
		font-weight: 600;
		font-size: 1.8rem;

		&:first-of-type {
			font-size: 1.6rem;
		}
	}

	.tns-outer {
		grid-row: 2 / 3;

		@include breakpoint(1200) {
			grid-row: 1 / 2;
			grid-column: 2 / 3;
			align-self: center;
		}
	}

	.tns-ovh {
		margin-bottom: 50px;

		@include breakpoint(1200) {
			width: 60vw;
			margin-bottom: 0;
		}
	}

	&__img {
		max-width: 100px;
		display: block;
		margin: 0 auto;
	}

	.buckets--internal & {
		grid-row: 3 / 4;
		grid-column: 2 / 3;
		background-color: $blanc;

		.tns-ovh {
			@include breakpoint(1200) {
				width: 48vw;
			}
		}
	}

	.brands__carousel_commercial {
		display: flex;
		align-items: center;
	}
}