/*---------------------------------------------------------------
    Core 
--------------------------------------------------------------- */

* {
	box-sizing: border-box;
}

html, body {
	font-size: 62.5%;
}

body {
	background: $blanc;
    color: $noir;
    font: 16px/1.5 $primaryFont, sans-serif;
	min-height: 100vh;
    -webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	padding: 0;
	margin: 0;

    &.fixed {
    	overflow: hidden;
    }
}

.container {
	max-width: $full-width;
	margin: 0 auto;
}

/* For lazy loaded elements */
[data-src] {
	opacity: 0;
	transition: opacity .4s ease-in-out;

	&[data-was-processed=true],
	&.loaded {
		opacity: 1;
	}
}

.darkgreenborder {
	border: 10px solid $brandGreen;
}

.lightgreenborder {
	border: 10px solid $brandLightGreen;
}

.lightgreyborder {
	border: 10px solid #e8e8e8;
}

.splitline {
	position: relative;

	&:before {
		content: '';
		width: 2px;
		height: 50px;
		display: block;
		background: linear-gradient(180deg, $brandDarkGreen 50%, lighten($brandLightGreen, 10%) 50%, lighten($brandLightGreen, 10%) 100%);
	}
}

.titlebox {
	padding: 50px 15px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	&--green {
		background-color: $brandLightGreen;

		&:before {
			position: absolute;
			bottom: -35px;
			left: 50%;
			transform: translateX(-50%) rotate(180deg);
		}
	}
	
	&__text {
		text-align: center;
		margin: 0;
		font-weight: 600;
		font-size: 1.8rem;

		&:first-of-type {
			font-size: 1.6rem;
		}

		&--white {
			color: $blanc;
		}

		&--green {
			color: $brandDarkGreen;
		}

		@include breakpoint(800) {
			font-size: 2.6rem;
			text-align: left;
			line-height: 1.4;

			&:first-of-type {
				font-size: 1.8rem;
			}
		}

		@include breakpoint(1200) {
			font-size: 3rem;
			text-align: left;
			line-height: 1.4;

			&:first-of-type {
				font-size: 2rem;
			}
		}
	}
}

a:not(.btn) {
	transition: all .4s ease-in-out;
}

#wp-notification {
    position: fixed;
    bottom: 0;
    z-index: 9999;
    width: 100%;
    display: none;
    color: $blanc;
    background: $brandGreen;
}

#wp-notification.open {
    display: block
}

.wp-notification-container {
    margin: 0 auto;
    color: $blanc;
    max-width: 1280px;
    text-align: center;
    padding: 10px
}

.wp-notification-container p {
    margin: 0
}

#wp-notification-toggle {
    @extend .btn;
		@extend .btn--white;
		cursor: pointer;

    &:hover {
        color: $noir;
    }
}

.wp-notification-container a {
	color: $noir;
	margin-right: 15px;

	&:hover {
			color: $noir;
	}
}

.wp-notification-container a:hover {
    text-decoration: none
}

@media (min-width:800px) {
    #wp-notification-toggle,
    .wp-notification-container a {
        display: inline-block;
        margin-left: 10px;
        margin-top: 0
    }
}

.ol-zoom {
	.ol-zoom-in,
	.ol-zoom-out {
		background-color: $brandDarkGreen;
		border-radius: 0;
		padding: 10px;
	}
}

.tns-ovh {
	width: 100vw;
	overflow: hidden;
}