.usp-reviews-section {
    // background: $brandLightGrey;
    background: $brandLightGreen;
    // border-bottom: 10px solid $brandGreen;

    .container {
        display: flex;
        align-items: stretch;
        flex-direction: column;

        @include breakpoint(800) {
            display: flex;
            flex-direction: row;
            align-items: stretch;

            .usps-section,
            .reviews-section {
                width: 50%;
                padding: 2em;
            }
        }

        .usps-section {

            padding: 2rem;

            ul {
                list-style: none;
                text-align: left;

                li {
                    // display: flex;
                    // align-items: center;
                    padding: .5em 0;

                    // p {
                       font-size: 1.2em;
                       color: $blanc;
                       font-weight: 800;
                    // }

                    .extra-content {
                        display: block;
                        font-weight: 400!important;
                        font-size: .8em;
                        color: $brandDarkGreen;
                    }

                    span {
                        font-weight: 700;
                        display: block;
                    }

                    svg {
                        color: $brandGreen;
                        width: 50px;
                        height: 50px;
                        margin-right: 15px;
                    }
                }
            }

            .btn {
                margin-top: 1em;
                text-align: center;
                background: $blanc;
                color: $brandDarkGreen;
            }
        }

        .reviews-section {
            text-align: center;
            overflow: hidden;

            .reviews__carousel {
                background: $blanc;
                height: 100%;
                padding: 1em;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: center;

                .each-review {
                    padding: 2em 3em;

                    p {
                        font-size: 1.25em;
                        font-weight: 600;
                    }

                    .stars {
                        margin-bottom: 2em;
                        svg {
                            color: $brandGreen;
                            // width: 25px;
                            // height: 25px;
                            margin: 3px;
                        }
                    }

                    .client-name {
                        display: block;
                        padding-top: 1.5em;
                        margin-top: 2em;
                        font-size: 1.2em;
                        border-top: 1px solid $brandGreen;
                    }
                }

            }
        }
    }


}