.header {
	background-color: $brandGreen;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 5;
	box-shadow: 0 1px 5px transparentize($noir, .75);

	.container {
		display: grid;
		grid-template-columns: 1fr 50px;
		grid-template-rows: repeat(3, minmax(min-content, max-content));
		position: relative;
		
		@include breakpoint(420) {
			grid-template-columns: 1fr 1fr 50px;
			grid-template-rows: repeat(2, minmax(min-content, max-content));
		}

		@include breakpoint(800) {
			grid-template-columns: 175px 1fr 50px;
		}

		@include breakpoint(1200) {
			grid-template-columns: 200px 1fr;
		}
	}

	@include breakpoint(800) {
		background-color: $blanc;
	}
}

.hamburger {
	background-color: $blanc;
	height: 50px;
	grid-column: 2 / 3;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $brandGreen;

	&:hover {
		color: $brandDarkGreen;
		background-color: $brandLightGrey;
	}

	@include breakpoint(420) {
		grid-column: 3 / 4;
	}

	@include breakpoint(800) {
		background-color: $brandDarkGreen;
		color: $blanc;
		grid-row: 1 / 3;
		height: 100%;
	}

	@include breakpoint(1200) {
		display: none;
	}
}

.phone-dropdown {
	grid-column: 1 / 2;
	width: 100%;
	background-color: $brandDarkGreen;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $blanc;
	position: relative;

	svg {
		color: $brandGreen;
		// transform: rotate(90deg);
		font-size: 1.4rem;
		margin-right: 5px;
	}

	&-list {
		position: absolute;
		top: 50px;
		left: 0;
		width: 100vh;
		background-color: $brandLightGrey;
		color: $noir;
		padding: 15px;
		visibility: hidden;
		opacity: 0;
		transition: all .4s ease-in-out;
		pointer-events: none;

		&--open {
			opacity: 1;
			visibility: visible;
			pointer-events: all;
		}

		&__text {
			margin-bottom: 10px;
		}

		a {
			color: $brandDarkGreen;
			text-decoration: none;
			font-weight: 900;
			display: block;

			&:hover {
				color: $brandGreen;
			}
		}
	}

	@include breakpoint(800) {
		display: none;
	}
}

.arrange_visit {
	grid-row: 2 / 3;
	grid-column: 1 / 3;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $blanc;
	padding: 10px;
	text-decoration: none;

	&__img {
		height: 18px;
		width: auto;
		display: block;
		margin-right: 5px;
	}

	@include breakpoint(420) {
		grid-row: 1 / 2;
		grid-column: 2 / 3;
	}

	@include breakpoint(800) {
		display: none;
	}
}

.logo {
	background-color: $blanc;
	grid-row: 3 / 4;
	grid-column: 1 / 4;
	padding: 10px;

	&__img {
		width: 100%;
		max-width: 120px;
		margin: 0 auto;
		display: block;

		@include breakpoint(800) {
			max-width: 100%;
			width: 180px;
		}
	}

	@include breakpoint(420) {
		grid-row: 2 / 3;
	}

	@include breakpoint(800) {
		grid-row: 1 / 3;
		grid-column: 1 / 2;
		justify-self: center;
		align-self: center;
	}
}

.desktop-phone-numbers {
	display: none;

	@include breakpoint(800) {
		display: block;
		grid-row: 1 / 2;
		grid-column: 2 / 3;
		padding: 15px;
	}

	@include breakpoint(1200) {
		border-bottom: 1px solid $brandLightGrey;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-wrap: wrap;
	}

	&__text {
		display: inline-block;
		margin: 10px;
	}

	.ld-phonenumber {
		display: inline-block;

		a {
			font-weight: 900;
			text-decoration: none;
			color: $brandDarkGreen;
			
			&:hover {
				color: $brandDarkGreen;
			}
		}
	}

	.btn {
		display: none;

		@include breakpoint(800) {
			display: flex;
			align-items: center;
			justify-content: center;
			max-width: 40%;
			margin: 0 auto;
		}

		@include breakpoint(1200) {
			max-width: initial;
			margin: 0;
		}
	}
}