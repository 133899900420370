$btnStyles: (
	white: (
		background-color: $blanc,
		color: $brandDarkGreen,
		background-hover: darken($blanc, 5%),
	),
	darkgreen: (
		background-color: $brandDarkGreen,
		color: $blanc,
		background-hover: $brandGreen,
		color-hover: $blanc
	)
);

button {
	border: 0;
	padding: 0;
	font-size: 1.6rem;
	font-family: $primaryFont;
	display: block;
	border-radius: 0;
}

.btn {
	display: inline-block;
	padding: 7px 15px;
	border-radius: 50px;
	min-width: 120px;
	transition: all .4s ease-in-out;
	text-decoration: none;
	margin: 5px 0;
	font-weight: 600;

	@each $stylename, $styles in $btnStyles {
		&--#{$stylename} {
			background-color: map-get($styles, background-color);
			color: map-get($styles, color);

			&:hover {
				background-color: map-get($styles, background-hover);
				color: map-get($styles, color-hover);
			}
		}
	}
}