/*---------------------------------------------------------------
	Forms
--------------------------------------------------------------- */

.nf-form-cont {
    margin-top: 20px;
    margin-bottom: 30px;
}

.nf-form-content {
    overflow: hidden;
}

nf-fields-wrap {
    overflow: hidden;
}

.nf-form-fields-required {
    color: red;
    font-size: 1.2rem;
    margin-bottom: 20px;
}

// nf-field {
//
// }
//
// .nf-field-label {
//
// }

.nf-field-container.nf-field-container {
    margin-bottom: 20px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea,
select {
    padding: 10px;
    border: 1px solid #ccc;
}

select {
    -webkit-appearance: none;
    border-radius: 0;
    background: #fff;
    background: url(../images/dropdown-arrow.svg) 95% center no-repeat;
}

input[type="button"] {
    background: $brandGreen;
    color: #fff;
    padding: 10px 30px;
    border: none;
    border-radius: 25px;

    &:hover {
        background: darken($brandGreen, 7%);
    }
}

.ninja-forms-field {
    padding: .5rem;

    // &::placeholder {
    //
    // }
}


/* Error handling */
.nf-error-wrap {

}

.nf-error-msg {

}


/*---------------------------------------------------------------
    Multi Step Form (with CF7)
--------------------------------------------------------------- */
 
$highlightColour : $brandGreen;

form {
    width: 100%;
    margin-bottom: 2em;
}
 
.cf-steps {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background: #f8f9f9;
  border: 1px solid #dfe6e8;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin-top: 50px;
 
  h3 {
    margin: 0;
    text-align: center;
    text-transform: none;
    font-weight: 700;
    padding: 60px 30px 30px;
    background: #efefef;
    font-size: 1.3em;
 
    @include breakpoint(1000) {
      padding: 70px 50px 40px;
      font-size: 1.6em;
    }
  }
 
  p {
    margin: 0 0 20px;
    font-size: 1em;
  }
 
  textarea {
    height: 100px;
    padding: 20px;
    border-radius: 20px;
    margin-top: 15px;
    border: 1px solid #ccc;
  }
 
  input,
  select {
    padding: 13px 15px;
    border-radius: 20px;
    margin-top: 15px;
    border: 1px solid #ccc;
  }
 
  input[type="submit"] {
    background: $highlightColour;
    border-color: $highlightColour;
    color: #fff;
    font-size: 1.2em;
    padding: 15px 40px;
    border-radius: 9999px;
    margin: 30px auto 0;
    display: block;
    font-weight: 700;
    transition: .3s;
 
    &:hover {
      background: darken($highlightColour, 8%);
    }
  }
 
  input[type="radio"],
  input[type="checkbox"] {
    opacity: 0;
    visibility: hidden;
    position: fixed;
  }
 
  .wpcf7-list-item {
    width: 100%;
    margin: 0 0 10px;
    display: inline-block;
 
    @include breakpoint(1000) {
      width: 49%;
      margin: 0 2% 2% 0;
 
      &:nth-child(even) { margin-right: 0; }
    }
  }
 
  input[type="radio"] + span,
  input[type="checkbox"] + span {
    border-radius: 20px;
    background: #fff;
    display: block;
    padding: 10px 15px;
    border: 1px solid #ccc;
    text-align: center;
    cursor: pointer;
    transition: .3s;
 
    @include breakpoint(1000) {
      padding: 15px;
      border-radius: 30px;
    }
 
    &:hover {
      border-color: #777;
    }
  }
 
  input[type="radio"]:checked + span,
  input[type="checkbox"]:checked + span {
    background: $highlightColour;
    border-color: darken($highlightColour, 10%);
    color: #fff;
    font-weight: 700;
  }
 
  .wpcf7-not-valid-tip {
    color: #dc3232;
    font-size: .7em;
    font-weight: normal;
    display: block;
    text-align: right;
    margin-top: 4px;
    margin-right: 15px;
  }
}
 
.cf-step {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
 
  &.active {
    opacity: 1;
    visibility: visible;
    position: static;
  }
}
 
.cf-step__form {
  padding: 30px;
 
  @include breakpoint(1000) {
    padding: 50px;
  }
}
 
.cf-steps__btns {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
 
.cf-step__btn {
  padding: 10px 40px;
  background: $brandDarkGreen;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
  transition: .3s;
 
  &:hover {
    opacity: .8;
  }
}
 
.cf-step__back {
  background: #888;
 
  &:only-child {
    margin: 0 auto;
  }
}
 
.cf-step__next {
  margin-left: auto;
}
 
.cf-steps__progress {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  display: flex;
  background: #dcdee0;
 
  > div {
    flex: 1;
    display: flex;
    text-align: center;
    font-size: .75em;
    border-right: 1px solid #c2cad0;
    color: #5e6367;
    position: relative;
 
    &:last-child { border-right: none; }
 
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background: $highlightColour;
      transition: .3s;
      z-index: 0;
    }
  }
 
  > div.active {
    border-right: 1px solid $highlightColour;
    border-left: 1px solid darken($highlightColour, 5%);
    color: #fff;
 
    &:first-child { border: none; }
 
    &:before {
      width: 100%;
    }
  }
 
  span {
    position: relative;
    z-index: 1;
    transition: .3s;
    margin: auto;
  }
}
 
.copy .cf-step__msg.cf-step__msg {
  display: none;
  color: red;
//   font-size: .8em;
  text-align: right;
  margin: 15px 0 0;
 
  &.active {
    display: block;
  }
}
 
.wpcf7-response-output {
  display: none;
  padding: 10px;
  border: 5px solid $highlightColour;
  text-align: center;
  font-weight: 700;
}
 
.wpcf7 .screen-reader-response {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  margin: 0;
  padding: 0;
  border: 0;
}
