.copy {

	&__content {
		padding: 25px 15px;
		display: flex;
		flex-wrap: wrap;
		background: $blanc;

		@include breakpoint(1200) {
			grid-column: 2 / 3;
			padding: 75px;
		}
	}

	h1.copy__title {
		color: $brandDarkGreen;
		line-height: 1.4;
		margin: 0 0 15px 0;
		width: 100%;

		.small {
			font-size: 1.6rem;
			display: block;
			color: $noir;
			font-weight: 400;
		}

		& + p {
			font-size: 1.8rem;
			font-weight: 600;
		}
	}

	h1 {
		width: 100%;
		line-height: 1.2;
		margin: 0 0 10px 0;
	}

	h2, h3, h4, h5, h6 {
		color: $brandDarkGreen;
		margin-bottom: 10px;
		width: 100%;
		line-height: 1.2;
	}

	p {
		width: 100%;
		font-weight: 400;
		margin-bottom: 15px;
	}

	ul, ol {
		margin: 0 0 15px;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
	}

	ol {
		list-style-position: inside;
		list-style: decimal;
		padding-left: 15px;

		li {
			margin: 5px 25px 5px 0;
		}
	}

	ul {
		list-style: none;

		li {
			// padding-left: 15px;
			margin: 5px 5px 5px 0;
			position: relative;
			width: 100%;

			.icon {
				color: $brandDarkGreen;
				margin-right: 7px;
			}
		}
	}

	a:not(.btn) {
		color: $brandGreen;
	}

	@include breakpoint(1200) {
		display: grid;
		grid-template-columns: 40% 1fr;
		grid-template-rows: 1fr;
	}
}

.content-cta {
	@extend .splitline;
	padding: 25px;
	background-color: $brandLightGrey;
	width: 100%;
	order: 1;

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		transform: rotate(180deg);
	}

	&__text {
		margin: 0;

		.ld-phonenumber {
			display: block;

			a {
				color: $brandDarkGreen;
				text-decoration: none;
				font-weight: 900;

				&:hover {
					color: $brandGreen;
				}
			}
		}
	}
}

.downloads {
	.copy {
		ul {
			li {
				width: auto;
				.icon-check-light {
					display: none;
				}

				.icon {
					margin-right: 0;
				}
			}
		}
	}
}