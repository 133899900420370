.mobile-navigation {
	padding: 25px;
	background-color: $brandLightGreen;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	transition: all .4s ease-in-out;
	z-index: 20;
	pointer-events: none;
	overflow-y: scroll;

	.container {
		overflow-y: scroll;
		display: flex;
		flex-wrap: wrap;
	}

	&--active {
		visibility: visible;
		opacity: 1;
		pointer-events: all;
	}

	&__col li {
		width: 100%;
		text-align: center;
	}

	.hamburger-close {
		background-color: $blanc;
		height: 60px;
		width: 60px;
		position: fixed;
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		border-left: 10px solid $brandGreen;
		border-bottom: 10px solid $brandGreen;
		z-index: 1000;
	}

	@include breakpoint(1200) {
		display: none;
	}
}

#menu-top-nav-desktop {
	display: none;

	@include breakpoint(1200) {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	@include breakpoint(1400) {
		padding: 0 15px;
	}
	& > li {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	li {
		transition: all .3s ease-in-out;
		height: 75px;
		
		&:nth-of-type(2) > a {
			flex-direction: row;
		}

		&.current-menu-item,
		&.current_page_item,
		&.current-menu-parent {

			& > a {
				font-weight: 900;
				color: $brandGreen;
			}

			&:hover > a {
				color: $blanc;
			}
		}

		&.menu-item-has-children:hover .sub-menu {
			opacity: 1;
			visibility: visible;
			pointer-events: all;
		}

		&:hover {
			background-color: $brandGreen;

			&:after{
				background-color: rgba($noir, .8);
			}
		}
	}

	a {
		color: $noir;
		text-decoration: none;
		padding: 0 10px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		line-height: 1.4;

		span {
			display: flex;
			align-items: center;
			justify-content: center;
			@include breakpoint(1400) {
				&:before {
					content: '\00a0'
				}
			}
		}

		&:hover {
			color: $blanc;
		}

		i {
			margin: 2px 3px;
		}

		@include breakpoint(1400) {
			flex-direction: row;
		}
	}

	.sub-menu {
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		position: absolute;
		top: 100%;
		left: 0;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		transition: all .3s ease-in-out;
		z-index: 2;
		box-shadow: 0 1px 5px transparentize($noir, .75);
		
		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0;
			height: 100%;
			width: 100%;
			background-color: $brandGreen;
		}
		
		&:before {
			box-shadow: 5px 1px 5px transparentize($noir, .75);
			left: 100%;
		}
		
		&:after {
			box-shadow: -5px 1px 5px transparentize($noir, .75);
			right: 100%;
		}

		&:hover {
			opacity: 1;
			visibility: visible;
			pointer-events: all;
		}

		li {
			z-index: 3;

			@include breakpoint(1200) {
				width: calc(100% / 4);
				height: 175px;
				border: 5px solid #222;
			}

			@include breakpoint(1600) {
				width: calc(100% / 5);
			}

			&.current-menu-item > a,
			&.current_page_item > a {

				&:before {
					background-color: rgba($brandDarkGreen, .6);
				}
	
				&:hover {
					color: $blanc;

					&:before {
						background-color: rgba($blanc, .4);
					}
				}
			}
		}

		a {
			position: relative;
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px;

			&:before,
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
			}

			&:before {
				content: '';
				background-color: transparent;
				z-index: 2;
				transition: background-color .3s ease-in-out;
			}

			img {
				object-fit: cover;
			}

			.menu-item-text {
				position: relative;
				z-index: 3;
				padding: 5px;
				background-color: rgba($brandGreen, .95);
				color: $blanc;
				font-weight: 600;
			}

			&:hover:before {
				background-color: rgba($blanc, .4);
			}
		}
	}
}

#sub-menu-underlay {
	background-color: rgba(#000, .8);
	position: fixed;
	//left: calc(-1 * (100vw - #{$full-width}));
	left: 0;
	top: 0;
	width: 100vw;
	//width: calc(100vw + (100vw - #{$full-width}));
	height: 100vh;
	visibility: hidden;
	opacity: 0;
	transition: all .3s ease-in-out;
	pointer-events: none;
	z-index: 1;

	&.visible {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
	}
}