/*---------------------------------------------------------------
	Typography
--------------------------------------------------------------- */
.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

// [data-icon]:before {
// 	font-family: "solo-blinds";
// 	content: attr(data-icon);
// 	font-style: normal;
// 	font-weight: normal;
// 	font-variant: normal;
// 	text-transform: none;
// 	line-height: 1;
// 	-webkit-font-smoothing: antialiased;
// 	-moz-osx-font-smoothing: grayscale;
// }

// [class^="icon-"]:before,
// [class*=" icon-"]:before,
// .has-icon-before,
// .has-icon-after {
// 	font-family: "solo-blinds";
// 	font-style: normal;
// 	font-weight: normal;
// 	font-variant: normal;
// 	text-transform: none;
// 	line-height: 1;
// 	-webkit-font-smoothing: antialiased;
// 	-moz-osx-font-smoothing: grayscale;
// }
  
// .icon-phone:before {
// 	content: "\61";
// }
// .icon-bars:before {
// 	content: "\64";
// }
// .icon-check:before {
// 	content: "\65";
// }
// .icon-clock:before {
// 	content: "\63";
// }
// .icon-angle-down:before {
// 	content: "\66";
// }
// .icon-angle-right:before {
// 	content: "\67";
// }
// .icon-angle-left:before {
// 	content: "\68";
// }
// .icon-star:before {
// 	content: "\68";
// }
  

// i {
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }
  

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: bold;
}

h1 {
	font-size: 3.6rem; //36px
}

h2 {
	font-size: 2.8rem; //28px
}

h3 {
	font-size: 2.4rem; //24px
}

h4 {
	font-size: 2rem; //20px
}

h5 {
	font-size: 1.8rem; //18px
}

h6 {
	font-size: 1.6rem; //16px
}

p {
	font-size: 1.6rem; //16px
	line-height: 1.6;
}

ul, ol {
	font-size: 1.6rem; //16px	
	margin: 0;
	padding: 0;
}

b, 
strong {
	font-weight: bold;
}

em {
	font-style: italic;
}

a {
	transition: .3s;

	&:visited {

	}

	&:focus {

	}

	&:hover {

	}
}

small {
	font-size: 1.4rem; // 14px;
}