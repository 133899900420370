.hero {
	display: grid;
	grid-template-rows: repeat(2, minmax(min-content, max-content));

	@include breakpoint(800) {
		grid-template-columns: 100px 1fr 1fr;
		grid-template-rows: 100px 1fr 100px;
	}

	@include breakpoint(1200) {
		grid-template-columns: 100px 450px 1fr;
	}

	&__content {
		background-color: $brandLightGreen;
		padding: 15px;
		width: 100%;
		text-align: center;

		@include breakpoint(800) {
			grid-row: 2 / 3;
			grid-column: 1 / 3;
			z-index: 2;
			padding: 25px;
			text-align: left;
		}

		@include breakpoint(1200) {
			padding: 50px;
		}
	}

	&__text {
		text-align: center;
		margin: 0;
		font-weight: 600;
		font-size: 1.8rem;

		&:first-of-type:not(:only-of-type) {
			font-size: 1.6rem;
		}

		&--white {
			color: $blanc;
		}

		&--green {
			color: $brandDarkGreen;
		}

		@include breakpoint(800) {
			font-size: 2.6rem;
			text-align: left;
			line-height: 1.4;

			&:first-of-type:not(:only-of-type) {
				font-size: 1.8rem;
			}
		}

		@include breakpoint(1200) {
			font-size: 3rem;
			text-align: left;
			line-height: 1.4;

			&:first-of-type:not(:only-of-type) {
				font-size: 2rem;
			}
		}
	}

	&__usps {
		list-style: none;
		margin: 10px 0 0;
		padding: 0;
	}

	&__reason {
		position: relative;
		padding-left: 20px;
		margin: 5px 0;
		color: $blanc;
		font-weight: 600;

		.icon {
			position: absolute;
			left: 0;
			top: 4px;
			font-size: 1.4rem;
			color: $brandDarkGreen;
		}
	}

	&__featured {
		height: 350px;

		picture {
			width: 100%;
			height: 100%;
			display: block;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			display: block;
		}
		
		@include breakpoint(800) {
			grid-row: 1 / 4;
			grid-column: 2 / 4;
			height: 100%;
			max-height: 450px;

			.parent-pageid-5290.page & {
				max-height: 580px;
			}
			
			.home.page & {
				max-height: none;
			}
		}
	}

	.btn {
		@include breakpoint(800) {
			margin-right: 10px;
		}
	}
}