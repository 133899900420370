.buckets {
	background-color: $brandLightGrey;

	@include breakpoint(1200) {
		display: grid;
		grid-template-rows: repeat(2, minmax(min-content, max-content));
		grid-template-columns: 30% 1fr;
	}

	.titlebox{
		grid-row: 1 / 2;

		@include breakpoint(800) {
			grid-column:  1 / 2;
		}
	}

	&__list {
		display: grid;
		grid-template-rows: repeat(2, minmax(min-content, max-content));
		grid-template-columns: repeat(2, 125px);
		padding: 50px 0;
		grid-gap: 15px;
		align-self: center;
		justify-self: center;

		@include breakpoint(480) {
			grid-template-rows: 1fr;
			grid-template-columns: repeat(4, minmax(min-content, 125px));
		}

		@include breakpoint(800) {
			grid-template-columns: repeat(4, minmax(min-content, 150px));
			grid-row: 2 / 3;
			grid-column:  1 / 3;
		}

		@include breakpoint(1200) {
			grid-column: 1 / 2;
			grid-template-columns: repeat(2, 150px);
		}

		.buckets--internal & {
			@include breakpoint(800) {
				grid-row: 1 / 3;
				grid-column: 2 / 3;
			}

			@include breakpoint(480) {
				grid-template-columns: repeat(4, minmax(min-content, 125px));
			}
	
			@include breakpoint(800) {
				grid-template-rows: repeat(3, minmax(min-content, max-content));
				grid-gap: 25px;
				grid-template-columns: repeat(4, minmax(min-content, 250px));
			}
	
			// @include breakpoint(1200) {
			// 	grid-template-columns: repeat(2, 150px);
			// }
		}
	}

	&__single {
		text-decoration: none;
		color: $noir;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;

		&:hover {
			.buckets__featured {
				opacity: .7;
			}
		}
	}

	&__title {
		display: block;
		text-align: center;
		font-size: 1.4rem;
	}

	&__featured {
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: all .4s ease-in-out;
		margin-bottom: 15px;
	}

	&__services-image {
		display: none;
		height: 500px;
		object-fit: cover;
		width: 100%;
		grid-row: 2 / 3;
		grid-column:  2 / 3;
		max-height: 650px;

		@include breakpoint(1200) {
			display: block;
			height: 100%;
		}
	}

	&--internal {
		@include breakpoint(800) {
			grid-template-rows: repeat(3, minmax(min-content, max-content));

			.titlebox:before {
				transform: translateY(-50%) rotate(90deg);
				left: 100%;
				top: 50%;
			}
		}
	}
}

.page.internal {
	.buckets {
		&__list {
			display: grid;
			grid-template-rows: repeat(2, minmax(min-content, max-content));
			grid-template-columns: repeat(2, minmax(min-content, 225px));
			padding: 50px 0;
			grid-gap: 15px;
			align-self: center;
			justify-self: center;
	
			@include breakpoint(480) {
				grid-template-rows: 1fr;
				grid-template-columns: repeat(4, minmax(min-content, 185px));
			}
	
			@include breakpoint(800) {
				grid-template-columns: repeat(4, minmax(min-content, 275px));
				grid-row: 2 / 3;
				grid-column:  1 / 3;
			}
	
			@include breakpoint(1200) {
				grid-column: 1 / 3;
				grid-template-columns: repeat(6, 185px);
			}
	
			@include breakpoint(1600) {
				grid-column: 1 / 3;
				grid-template-columns: repeat(6, 250px);
			}
		}

		.brands {
			overflow-x: hidden;
		}
	}
}

.step-by-step {
	display: none;
	
	@include breakpoint(800) {
		background-color: $blanc;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		padding: 25px 0;
		grid-row: 1 / 2;
		grid-column: 2 / 3;
	}

	h2 {
		display: block;
		width: 100%;
		font-weight: 600;
		color: $brandGreen;
		text-align: center;
	}

	&__step {
		width: calc((100% / 3) - 60px);
		padding: 15px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;

		@include breakpoint(1400) {
			padding: 30px;
		}
	}

	&__img {
		max-height: 35px;
		width: auto;
		height: 100%;
		display: block;
		margin-bottom: 10px;
	}

	&__title {
		font-weight: 600;
		margin-bottom: 5px;
	}

	&__text {
		text-align: center;
	}

	&__link {
		color: $brandDarkGreen;
		text-decoration: none;

		&:hover {
			color: $noir;
		}
	}

	&__arrow {
		width: 60px;
		height: auto;
		display: block;
		margin-top: -50px;
		
		&[data-was-processed=true] {
			opacity: 0.3; // Override for the lazy loading opacity
		}
	}

	.buckets--internal & {
		@include breakpoint(800) {
			flex-direction: column;
			grid-row: 2 / 4;
			grid-column: 1 / 2;

			&__step {
				width: 70%;
			}

			&__arrow {
				margin-top: 15px;
				margin-left: 150px;
				transform: rotate(90deg);
			}
		}
	}
}