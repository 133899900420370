.blog-page {
	display: grid;
	grid-template-rows: repeat(2, minmax(min-content, max-content));
	max-width: $full-width;
	margin: 0 auto;
	padding: 25px 15px;

	@include breakpoint(800) {
		padding: 50px 15px;
	}

	@include breakpoint(1200) {
		padding: 50px 0;
	}

	@include breakpoint(800) {
		grid-template-columns: 30% 1fr;
		grid-template-rows: 1fr;
		grid-gap: 25px;
	}

	&--single {
		.single-article {
			width: 100%;
			box-shadow: none;
			border: 0;

			&__title {
				font-size: 3.6rem;
			}

			&:hover:after {
				box-shadow: none;
			}
		}
	}
}

.articles {
	width: 100%;
	grid-row: 2 / 3;

	position: relative;

	&-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		transition: opacity .3s ease-in-out;

		&--fade {
			opacity: 0;
		}
	}

	@include breakpoint(800) {
		grid-row: 1 / 2;
		grid-column: 2 / 3;
	}
}

.single-article {
	width: 100%;
	margin-bottom: 15px;
	padding: 15px;
	border: 1px solid $brandLightGrey;
	box-shadow: 3px 3px 5px rgba($noir, .1);
	text-decoration: none;
	border-radius: 5px;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		box-shadow: 5px 5px 8px rgba($noir, .1);
		opacity: 0;
		transition: opacity .3s ease-in-out;
	}

	&__title {
		color: $brandDarkGreen;
		font-weight: 900;
		margin-bottom: 5px;
		font-size: 2rem;
		transition: color .3s ease-in-out;
		width: 100%;
	}

	&__more {
		color: $brandDarkGreen;
		font-weight: 900;
		transition: color .3s ease-in-out;
	}
	
	&__text {
		color: $noir;
	}

	@include breakpoint(420) {
		width: 49%;
	}
	
	@include breakpoint(800) {
		width: 32%;
	}

	&:hover:after {
		opacity: 1;
	}

	&:hover .single-article__title {
		color: $brandLightGreen;
	}

	&:hover .single-article__more {
		color: $brandGreen;
	}
}

.next a,
.prev a {
	display: inline-block;
	text-decoration: none;
	background: $brandGreen;
	color: #fff;
	padding: 5px 10px;

	&:hover {
		background: darken($brandGreen, 5%);
	}
}

.sidebar {
	grid-row: 1/ 2;
	width: 100%;
	background-color: rgba($brandGreen, .2);
	padding: 25px;
	
	@include breakpoint(800) {
		grid-column: 1 / 2;
	}

	&-block {
		width: 100%;
		margin-bottom: 15px;

		&__link {
			color: $noir;
			text-decoration: none;
		}

		&__title {
			margin-bottom: 10px;
		}

		&--filter &__link {
			background-color: $brandDarkGreen;
			color: $blanc;
			padding: 5px;
			margin-right: 10px;
			margin-bottom: 10px;
			display: inline-block;

			&:hover {
				transform: scale(1.1);
			}
		}

		// Ajax link styles
		.ajax-cat-link--active {
			font-weight: 900;
			background-color: $brandLightGreen;
			color: $noir;
		}
	}
}

.sk-cube-grid {
	width: 45px;
	height: 45px;
	border-radius: 50%;
	overflow: hidden;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-wrap: wrap;
	opacity: 0;
	transition: all .4s ease-in-out;

	&--visible {
		opacity: 1;
	}
  }
  
  .sk-cube-grid .sk-cube {
	width: calc(100% / 3);
	height: calc(100% / 3);
	background-color: $brandDarkGreen;
	-webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
			animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
  }
  .sk-cube-grid .sk-cube1 {
	-webkit-animation-delay: 0.2s;
			animation-delay: 0.2s; }
  .sk-cube-grid .sk-cube2 {
	-webkit-animation-delay: 0.3s;
			animation-delay: 0.3s; }
  .sk-cube-grid .sk-cube3 {
	-webkit-animation-delay: 0.4s;
			animation-delay: 0.4s; }
  .sk-cube-grid .sk-cube4 {
	-webkit-animation-delay: 0.1s;
			animation-delay: 0.1s; }
  .sk-cube-grid .sk-cube5 {
	-webkit-animation-delay: 0.2s;
			animation-delay: 0.2s; }
  .sk-cube-grid .sk-cube6 {
	-webkit-animation-delay: 0.3s;
			animation-delay: 0.3s; }
  .sk-cube-grid .sk-cube7 {
	-webkit-animation-delay: 0s;
			animation-delay: 0s; }
  .sk-cube-grid .sk-cube8 {
	-webkit-animation-delay: 0.1s;
			animation-delay: 0.1s; }
  .sk-cube-grid .sk-cube9 {
	-webkit-animation-delay: 0.2s;
			animation-delay: 0.2s; }
  
  @-webkit-keyframes sk-cubeGridScaleDelay {
	0%, 70%, 100% {
	//   -webkit-transform: scale3D(1, 1, 1);
	// 		  transform: scale3D(1, 1, 1);
	opacity: 0;
	} 35% {
	//   -webkit-transform: scale3D(0, 0, 1);
	// 		  transform: scale3D(0, 0, 1); 
	opacity: 1;
	}
  }
  
  @keyframes sk-cubeGridScaleDelay {
	0%, 70%, 100% {
	  -webkit-transform: scale3D(1, 1, 1);
			  transform: scale3D(1, 1, 1);
	} 35% {
	  -webkit-transform: scale3D(0, 0, 1);
			  transform: scale3D(0, 0, 1);
	} 
  }