/**
 * COLOURS
 **/
$blanc: #FFF;
$noir: #333;
$brandGreen: #6aaf33;
$brandDarkGreen: darken($brandGreen, 10%);
$brandLightGreen: lighten($brandGreen, 8%);
$brandLightGrey: #EFEFEF;

/**
 * FONTS
 **/
$primaryFont: 'proxima-nova', sans-serif;

/**
 * EVERYTHING ELSE
 **/
$full-width: 1600px;
