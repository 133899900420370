.casestudies {

	.titlebox {
		@include breakpoint(800) {
			grid-row: 2 / 3;
			grid-column: 1 / 2;
		}

		@include breakpoint(1200) {
			grid-row: 3 / 4;
		}
	}

	@include breakpoint(800) {
		display: grid;
		grid-template-columns: 450px 1fr;
		grid-template-rows: repeat(2, 300px);
	}

	@include breakpoint(1200) {
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		grid-template-columns: 300px 1fr;
		grid-template-rows: 1fr 300px 300px;
		height: 100%;
	}

	&__img {
		width: 100%;
		object-fit: cover;
		height: auto;
		max-height: 350px;
		display: block;

		@include breakpoint(800) {
			grid-row: 1 / 3;
			grid-column: 1 / 3;
			height: 100%;
			max-height: 100%;
		}

		@include breakpoint(1200) {
			grid-row: 1 / 4;
		}
	}

	.btn {
		margin: 10px;
		display: inline-block;
	}
}