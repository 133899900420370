.sidebar__cta {
  padding: 30px;

  h4 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.6rem;
  }

  a {
    display: inline-block;
    margin-top: 20px;
  }
}