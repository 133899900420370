.page-template-page-testimonials {
    .single-article {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;

        .stars {
            margin: 10px 0;
            text-align: center;
            svg {
                color: $brandGreen;
                // width: 25px;
                // height: 25px;
                margin: 3px;
            }
        }
    
        .single-article__title {
            margin-top: 10px;
            text-align: right;
        }
    }
}